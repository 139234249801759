<template>
  <div
    class="innerContent"
    style="
      height: calc(100vh);
      width: 100%;
      min-width: 85%;
      max-width: 100%;
      background: var(--v-greyRaised-base) !important;
    "
  >
        <v-row class="pa-2 mx-1">
          <v-col cols="12" sm="6" md="4">
            <v-card class="infoCards" id="company-info" flat>
              <v-card-title> Company Info </v-card-title>
              <v-card-text
                class="my-0 py-0"
              >
                <v-list dense>
                  <v-list-item v-if="company.relatedOrganisation.countryCode">
                    <v-list-item-action>
                      <v-avatar size="24">
                        <v-img
                          v-if="company.relatedOrganisation.countryCode"
                          contain
                          :src="`https://cdn.loglive.io/flags/4x3/${company.relatedOrganisation.countryCode.toLowerCase()}.svg`"
                        >
                        </v-img>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ company.relatedOrganisation.country }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-icon>tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ company.relatedOrganisation.logliveCode }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        LogLive Code
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="company.relatedOrganisation.registrationNumber"
                  >
                    <v-list-item-action>
                      <v-icon>tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ company.relatedOrganisation.registrationNumber }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Registration No.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="company.relatedOrganisation.vatNumber">
                    <v-list-item-action>
                      <v-icon>tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ company.relatedOrganisation.vatNumber }}
                      </v-list-item-title>
                      <v-list-item-subtitle> VAT No. </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="company.relatedOrganisation.emailAddress">
                    <v-list-item-action>
                      <v-icon>email</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ company.relatedOrganisation.emailAddress }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Primary Email Address
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="company.relatedOrganisation.emailAddressAlt"
                  >
                    <v-list-item-action>
                      <v-icon>email</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ company.relatedOrganisation.emailAddressAlt }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Alternative Email Address
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="company.relatedOrganisation.telephoneNumber"
                  >
                    <v-list-item-action>
                      <v-icon>phone</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ company.relatedOrganisation.telephoneNumber }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Primary Telephone No.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    v-if="company.relatedOrganisation.telephoneNumberAlt"
                  >
                    <v-list-item-action>
                      <v-icon>phone</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ company.relatedOrganisation.telephoneNumberAlt }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Alternative Telephone No.
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider
                    v-if="contactPersons && contactPersons.length > 0"
                  ></v-divider>
                  <v-subheader
                    v-if="contactPersons && contactPersons.length > 0"
                  >
                    <v-icon class="mr-1">person</v-icon> Points of
                    Contact</v-subheader
                  >
                  <v-divider
                    v-if="contactPersons && contactPersons.length > 0"
                  ></v-divider>
                  <v-list-item
                    v-for="person in contactPersons"
                    :key="person.id"
                  >
                    <v-list-item-action>
                      <v-avatar :color="person.avatar ? 'white':'secondary'" size="36">
                        <v-img
                          v-if="person.avatar"
                          :src="person.avatar"
                          referrerpolicy="no-referrer"
                          contain
                        ></v-img>
                        <h3 v-else>
                          {{ person.firstname.charAt(0) }}                        </h3>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ person.firstname }} {{ person.surname }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="person.position">
                        <v-icon class="mr-1" small>star</v-icon>
                        {{ person.position }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="person.emailAddress">
                        <v-icon class="mr-1" small>email</v-icon>
                        {{ person.emailAddress }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="person.mobileNumber">
                        <v-icon class="mr-1" small>phone</v-icon>
                        {{ person.mobileNumber }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-divider
                    v-if="
                      company.relatedOrganisation.organisationAddresses &&
                      company.relatedOrganisation.organisationAddresses.length >
                        0
                    "
                  ></v-divider>
                  <v-subheader
                    v-if="
                      company.relatedOrganisation.organisationAddresses &&
                      company.relatedOrganisation.organisationAddresses.length >
                        0
                    "
                  >
                    <v-icon class="mr-1">location_on</v-icon> Listed Addresses
                    <!-- <v-btn icon
                                            color="primary"
                                            @click="addAddress"><v-icon>add_circle_outline</v-icon></v-btn> -->
                  </v-subheader>
                  <v-divider
                    v-if="
                      company.relatedOrganisation.organisationAddresses &&
                      company.relatedOrganisation.organisationAddresses.length >
                        0
                    "
                  ></v-divider>

                  <v-list-item
                    v-for="address in company.relatedOrganisation
                      .organisationAddresses"
                    :key="address.id"
                  >
                    <v-list-item-content>
                      <div style="margin-bottom: 10px">
                        <u style="font-size: 14px">{{ address.alias }}</u>
                        <v-icon right v-if="address.isHQ" color="success"
                          >high_quality
                        </v-icon>
                        <v-icon
                          right
                          v-if="address.isDeliveryAddress"
                          color="success"
                        >
                          local_shipping</v-icon
                        >
                        <v-icon
                          right
                          v-if="address.isDocsAddress"
                          color="success"
                        >
                          description</v-icon
                        >
                      </div>
                      <span
                        v-if="address.addressLine1"
                        style="font-size: 12px"
                        >{{ address.addressLine1 }}</span
                      >
                      <span
                        v-if="address.addressLine2"
                        style="font-size: 12px"
                        >{{ address.addressLine2 }}</span
                      >
                      <span
                        v-if="address.addressLine3"
                        style="font-size: 12px"
                        >{{ address.addressLine3 }}</span
                      >
                      <span
                        v-if="address.addressLine4"
                        style="font-size: 12px"
                        >{{ address.addressLine4 }}</span
                      >
                      <span v-if="address.town" style="font-size: 12px">{{
                        address.town
                      }}</span>
                      <span
                        v-if="address.subdivision"
                        style="font-size: 12px"
                        >{{ address.subdivision }}</span
                      >
                      <span v-if="address.country" style="font-size: 12px">{{
                        address.country
                      }}</span>
                      <span style="font-size: 12px">{{
                        address.postalCode
                      }}</span>
                    </v-list-item-content>
                    <!-- <v-list-item-action>
                                            <v-row justify="center">
                                                <v-btn icon
                                                    @click="editAddress(address, index)"><v-icon>edit</v-icon></v-btn>
                                                <v-btn icon color="red" @click="deleteAddress(address, index)"><v-icon>delete</v-icon></v-btn>
                                            </v-row>
                                        </v-list-item-action> -->
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="8">
            <v-card flat class="infoCards">
              <v-card-text>
                <v-row class="pa-2">
                  <v-col cols="12" md="6">
                    <v-list dense>
                      <v-text-field
                        label="Internal Alias"
                        v-model="company.alias"
                        outlined
                        dense
                        @change="saveInternalAlias"
                      ></v-text-field>
                      <v-subheader style="font-size: 16px">
                        <v-icon class="mr-2" color="grey">public</v-icon>
                        Countries of Operation
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          color="primary"
                          @click="countryModal = true"
                        >
                          <v-icon>add_circle_outline</v-icon>
                        </v-btn>
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-list dense>
                        <v-list-item
                          v-if="
                            company.organisationRelationshipCountries.length ==
                            0
                          "
                        >
                          <v-list-item-content class="text-center">
                            <span style="font-size: 12px; color: grey"
                              >No countries selected</span
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-for="country in company.organisationRelationshipCountries"
                          :key="country.id"
                          style="height: 50px"
                        >
                          <v-list-item-action>
                            <v-avatar
                              size="32"
                              class="mr-2"
                              v-if="country.countryRegion.iso2"
                            >
                              <v-img
                                contain
                                :src="`https://cdn.loglive.io/flags/4x3/${country.countryRegion.iso2.toLowerCase()}.svg`"
                              >
                              </v-img>
                            </v-avatar>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ country.countryRegion.name }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-divider></v-divider>
                      <v-subheader style="font-size: 16px">
                        <v-icon class="mr-2" color="grey">category</v-icon>
                        Traded Products
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          color="primary"
                          @click="productModal = true"
                        >
                          <v-icon>add_circle_outline</v-icon>
                        </v-btn>
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-list dense>
                        <v-list-item
                          v-if="
                            company.organisationRelationshipProducts.length == 0
                          "
                        >
                          <v-list-item-content class="text-center">
                            <span style="font-size: 12px; color: grey"
                              >No products selected</span
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-for="product in company.organisationRelationshipProducts"
                          :key="product.id"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ product.product.name }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                      <v-divider></v-divider>
                      <v-subheader style="font-size: 16px">
                        <v-icon class="mr-2" color="grey"
                          >directions_boat</v-icon
                        >
                        Shipping Lines
                        <v-spacer></v-spacer>
                        <v-btn
                          icon
                          color="primary"
                          @click="shippingLineModal = true"
                        >
                          <v-icon>add_circle_outline</v-icon>
                        </v-btn>
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-list dense>
                        <v-list-item
                          v-if="
                            company.organisationRelationshipTransports.length ==
                            0
                          "
                        >
                          <v-list-item-content class="text-center">
                            <span style="font-size: 12px; color: grey"
                              >No Shipping Lines selected</span
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-for="shippingLine in company.organisationRelationshipTransports"
                          :key="shippingLine.id"
                          @click="editShippingLine(shippingLine)"
                        >
                          <v-list-item-action>
                            <v-avatar
                              :color="
                                shippingLine.carrier.logo
                                  ? 'white'
                                  : 'secondary'
                              "
                            >
                              <v-img
                                v-if="shippingLine.carrier.logo"
                                :src="shippingLine.carrier.logo"
                                contain
                              ></v-img>
                              <h2 v-else>
                                {{ shippingLine.carrier.name.charAt(0) }}
                              </h2>
                            </v-avatar>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ shippingLine.carrier.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <v-icon small color="grey" class="mr-2"
                                >description</v-icon
                              >
                              {{ shippingLine.contractNumber }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle
                              v-if="shippingLine.contractOwner"
                            >
                              <v-icon small color="grey" class="mr-2"
                                >business</v-icon
                              >
                              {{ shippingLine.contractOwner.name }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-list dense>
                        <v-divider></v-divider>
                        <v-subheader style="font-size: 16px">
                          <v-icon class="mr-2" color="grey"
                            >maps_home_work</v-icon
                          >
                          Production Aliases
                        </v-subheader>
                        <v-divider></v-divider>
                        <v-chip small @click="aliasModal = true" class="ma-1">
                          <v-icon small left>add</v-icon>
                          <span v-if="!aliasModal">Add Alias</span>
                          <v-text-field
                            v-else
                            dense
                            autofocus
                            v-model="alias"
                            hide-details
                            @keydown.enter="saveAlias"
                          ></v-text-field>
                        </v-chip>
                        <v-chip
                          v-for="alias in productionAliases"
                          :key="alias.id"
                          small
                          class="ma-1"
                        >
                          {{ alias.code }}
                          <v-icon small right @click="removeAlias(alias)"
                            >cancel</v-icon
                          >
                        </v-chip>
                        <!-- <v-list-item v-if="productionAliases.length == 0">
                          <v-list-item-content class="text-center">
                            <span style="font-size: 12px; color: grey"
                              >No Production Aliases</span
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                          v-for="item in productionAliases"
                          :key="item.id"
                          @click="editProductionAlias(item)"
                        >
                          <v-list-item-action>
                            <v-avatar
                              size="32"
                              :color="item.logo ? 'white' : 'secondary'"
                            >
                              <v-img
                                :src="item.logo"
                                v-if="item.logo"
                                contain
                              ></v-img>
                              <h3 v-else>
                                {{ item.name.charAt(0) }}
                              </h3>
                            </v-avatar>
                          </v-list-item-action>
                          <v-list-item-title>
                            <span v-if="item.alias">{{ item.alias }}</span>
                            <span v-else>{{ item.name }}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <v-chip
                              x-small
                              v-for="alias in item.aliases"
                              :key="alias.id"
                              label
                            >
                              {{ alias.code }}
                            </v-chip>
                          </v-list-item-subtitle>
                        </v-list-item> -->
                      </v-list>
                    </v-list>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-list dense :key="fieldKey">
                      <!-- <v-list-item>
                        <v-list-item-action>
                          <v-icon color="grey">business</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Type of Business
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip-group column multiple class="text-center">
                            <v-chip
                              class="mx-1"
                              @click="updateField('export')"
                              outlined
                              small
                              :color="company.export ? 'green' : ''"
                            >
                              <v-icon left small v-if="company.export"
                                >check</v-icon
                              >
                              Export</v-chip
                            >
                            <v-chip
                              class="mx-1"
                              @click="updateField('import')"
                              outlined
                              small
                              :color="company.import ? 'green' : ''"
                            >
                              <v-icon left small v-if="company.import"
                                >check</v-icon
                              >
                              Import</v-chip
                            >
                          </v-chip-group>
                        </v-list-item-action>
                      </v-list-item> -->

                      <v-list-item>
                        <v-list-item-action>
                          <v-icon color="grey">business</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Contact Type </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-chip-group column multiple class="text-center">
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.isCustomer = !company.isCustomer),
                                  saveRelationship()
                              "
                              :color="company.isCustomer ? 'green' : 'grey'"
                            >
                              <v-icon
                                v-if="company.isCustomer"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Customer
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.isSupplier = !company.isSupplier),
                                  saveRelationship()
                              "
                              :color="company.isSupplier ? 'green' : 'grey'"
                            >
                              <v-icon
                                v-if="company.isSupplier"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Supplier
                            </v-chip>
                          </v-chip-group>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>

                    <v-subheader>
                      <v-icon class="mr-2">swap_horiz</v-icon> Relationship
                      Network Functions</v-subheader
                    >

                    <v-col cols="12" class="text-center my-0 py-0">
                      <v-row align="center" id="relationship-network-functions">
                        <v-col cols="12" class="text-center" sm="12">
                          <v-chip-group column multiple class="text-center">
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.producer = !company.producer),
                                  saveRelationship()
                              "
                              :color="company.producer ? 'green' : 'grey'"
                            >
                              <v-icon
                                v-if="company.producer"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Producer
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.coldstore = !company.coldstore),
                                  saveRelationship()
                              "
                              :color="company.coldstore ? 'green' : 'grey'"
                            >
                              <v-icon
                                v-if="company.coldstore"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Coldstore
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.consignee = !company.consignee),
                                  saveRelationship()
                              "
                              :color="company.consignee ? 'green' : 'grey'"
                            >
                              <v-icon
                                v-if="company.consignee"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Consignee
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.shipper = !company.shipper),
                                  saveRelationship()
                              "
                              :color="company.shipper ? 'green' : 'grey'"
                            >
                              <v-icon
                                v-if="company.shipper"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Shipper
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.forwarder = !company.forwarder),
                                  saveRelationship()
                              "
                              :color="company.forwarder ? 'green' : 'grey'"
                            >
                              <v-icon
                                v-if="company.forwarder"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Forwarder
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.notifyParty = !company.notifyParty),
                                  saveRelationship()
                              "
                              :color="company.notifyParty ? 'green' : 'grey'"
                            >
                              <v-icon
                                v-if="company.notifyParty"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Notify Party
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.buyer = !company.buyer),
                                  saveRelationship()
                              "
                              :color="company.buyer ? 'green' : 'grey'"
                            >
                              <v-icon
                                v-if="company.buyer"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Buyer
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.transporter = !company.transporter),
                                  saveRelationship()
                              "
                              :color="company.transporter ? 'green' : 'grey'"
                            >
                              <v-icon
                                v-if="company.transporter"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Transporter
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.shippingLine = !company.shippingLine),
                                  saveRelationship()
                              "
                              :color="company.shippingLine ? 'green' : 'grey'"
                            >
                              <v-icon
                                v-if="company.shippingLine"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Carrier
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.pluginProvider =
                                  !company.pluginProvider),
                                  saveRelationship()
                              "
                              :color="company.pluginProvider ? 'green' : 'grey'"
                            >
                              <v-icon
                                v-if="company.pluginProvider"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Plugin Provider
                            </v-chip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-chip
                                  v-on="on"
                                  outlined
                                  small
                                  @click="
                                    company.vasProvider = !company.vasProvider
                                  "
                                  :color="
                                    company.vasProvider ? 'green' : 'grey'
                                  "
                                >
                                  <v-icon
                                    v-if="company.vasProvider"
                                    small
                                    color="green"
                                    class="mr-1"
                                    >check</v-icon
                                  >
                                  VAS Provider
                                </v-chip>
                              </template>
                              <span>Value Added Service Provider</span>
                            </v-tooltip>
                          </v-chip-group>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-subheader>
                      <v-icon class="mr-2">swap_horiz</v-icon> My Network
                      Functions</v-subheader
                    >
                    <v-col cols="12" class="text-center my-0 py-0">
                      <v-row align="center" id="my-network-functions">
                        <v-col cols="12" class="text-center" sm="12">
                          <v-chip-group column multiple class="text-center">
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.inverse.producer =
                                  !company.inverse.producer),
                                  saveRelationship()
                              "
                              :color="
                                company.inverse.producer ? 'green' : 'grey'
                              "
                            >
                              <v-icon
                                v-if="company.inverse.producer"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Producer
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.inverse.coldstore =
                                  !company.inverse.coldstore),
                                  saveRelationship()
                              "
                              :color="
                                company.inverse.coldstore ? 'green' : 'grey'
                              "
                            >
                              <v-icon
                                v-if="company.inverse.coldstore"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Coldstore
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.inverse.consignee =
                                  !company.inverse.consignee),
                                  saveRelationship()
                              "
                              :color="
                                company.inverse.consignee ? 'green' : 'grey'
                              "
                            >
                              <v-icon
                                v-if="company.inverse.consignee"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Consignee
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.inverse.shipper =
                                  !company.inverse.shipper),
                                  saveRelationship()
                              "
                              :color="
                                company.inverse.shipper ? 'green' : 'grey'
                              "
                            >
                              <v-icon
                                v-if="company.inverse.shipper"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Shipper
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.inverse.forwarder =
                                  !company.inverse.forwarder),
                                  saveRelationship()
                              "
                              :color="
                                company.inverse.forwarder ? 'green' : 'grey'
                              "
                            >
                              <v-icon
                                v-if="company.inverse.forwarder"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Forwarder
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.inverse.notifyParty =
                                  !company.inverse.notifyParty),
                                  saveRelationship()
                              "
                              :color="
                                company.inverse.notifyParty ? 'green' : 'grey'
                              "
                            >
                              <v-icon
                                v-if="company.inverse.notifyParty"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Notify Party
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.inverse.buyer =
                                  !company.inverse.buyer),
                                  saveRelationship()
                              "
                              :color="company.inverse.buyer ? 'green' : 'grey'"
                            >
                              <v-icon
                                v-if="company.inverse.buyer"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Buyer
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.inverse.transporter =
                                  !company.inverse.transporter),
                                  saveRelationship()
                              "
                              :color="
                                company.inverse.transporter ? 'green' : 'grey'
                              "
                            >
                              <v-icon
                                v-if="company.inverse.transporter"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Transporter
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.inverse.shippingLine =
                                  !company.inverse.shippingLine),
                                  saveRelationship()
                              "
                              :color="
                                company.inverse.shippingLine ? 'green' : 'grey'
                              "
                            >
                              <v-icon
                                v-if="company.inverse.shippingLine"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Carrier
                            </v-chip>
                            <v-chip
                              outlined
                              small
                              @click="
                                (company.inverse.pluginProvider =
                                  !company.inverse.pluginProvider),
                                  saveRelationship()
                              "
                              :color="
                                company.inverse.pluginProvider
                                  ? 'green'
                                  : 'grey'
                              "
                            >
                              <v-icon
                                v-if="company.inverse.pluginProvider"
                                small
                                color="green"
                                class="mr-1"
                                >check</v-icon
                              >
                              Plugin Provider
                            </v-chip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-chip
                                  v-on="on"
                                  outlined
                                  small
                                  @click="
                                    company.inverse.vasProvider =
                                      !company.inverse.vasProvider
                                  "
                                  :color="
                                    company.inverse.vasProvider
                                      ? 'green'
                                      : 'grey'
                                  "
                                >
                                  <v-icon
                                    v-if="company.inverse.vasProvider"
                                    small
                                    color="green"
                                    class="mr-1"
                                    >check</v-icon
                                  >
                                  VAS Provider
                                </v-chip>
                              </template>
                              <span>Value Added Service Provider</span>
                            </v-tooltip>
                          </v-chip-group>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

    <v-dialog
      v-model="teamModal"
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title
            ><v-icon class="mr-2">groups</v-icon>Assigned Teams</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="saveTeams" :loading="savingTeams"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="teamModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="team in allTeams"
              :key="team.id"
              :style="{ 'border-left': `5px solid ${team.colour}` }"
              class="my-1"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ team.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox
                  color="primary"
                  v-model="selectedTeams"
                  :value="team.id"
                ></v-checkbox>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="accountManagerModal"
      width="750px"
      :fullscreen="$vuetify.breakpoint.mobile"
      persistent
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title>
            <v-icon class="mr-2">person</v-icon>
            Account Manager
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="accountManagerModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row justify="end" class="mb-2">
            <v-col cols="12" sm="12" md="8" lg="6">
              <el-input
                suffix-icon="el-icon-search"
                v-model="userSearch"
                clearable
                placeholder="Search"
              >
              </el-input>
            </v-col>
          </v-row>
          <v-data-table
            id="user-table"
            dense
            :items="users"
            :loading="loadingUsers"
            :search="userSearch"
            :headers="headers"
            style="cursor: pointer"
            @click:row="updateAccountManager"
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addressDialog"
      width="750px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <AddressModal
        :item="addressItem"
        :dialogVisible="addressDialog"
        @close="(addressDialog = false), (addressItem = {})"
        transition="scale-transition"
        @save="saveAddress"
      />
    </v-dialog>

    <!-- Country modal -->
    <v-dialog
      v-model="countryModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="loadingCountries">
        <v-toolbar flat>
          <v-toolbar-title> Select a Country </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="countryModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            autofocus
            dense
            prefix-inner-icon="search"
            placeholder="Search"
            outlined
            clearable
            v-model="searchCountry"
          ></v-text-field>
          <v-list
            dense
            style="max-height: 50vh; overflow-y: auto"
            :key="countryRefresh"
          >
            <v-list-item
              v-for="country in filterCountries"
              :key="country.id"
              style="height: 50px"
              @click="addOperatingCountry(country)"
            >
              <v-list-item-action>
                <v-avatar size="32" v-if="country.iso2">
                  <v-img
                    contain
                    :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"
                  >
                  </v-img>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ country.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon
                  color="success"
                  v-if="
                    company.organisationRelationshipCountries
                      .map((x) => x.countryRegionId)
                      .includes(country.id)
                  "
                  >check</v-icon
                >
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              v-if="filterCountries.length == 0 && !loadingCountries"
            >
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey"
                  >No countries found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Country modal -->
    <v-dialog
      v-model="productModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="productLoading">
        <v-toolbar flat>
          <v-toolbar-title> Select a Product </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="productModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            autofocus
            dense
            prefix-inner-icon="search"
            placeholder="Search"
            outlined
            clearable
            v-model="searchProduct"
          ></v-text-field>
          <v-list
            dense
            style="max-height: 50vh; overflow-y: auto"
            :key="productKey"
          >
            <v-list-item
              v-for="product in filterProducts"
              :key="product.id"
              @click="addTradedProduct(product)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ product.name }}
                </v-list-item-title>
                <v-list-item-subtitle
                  style="font-size: 12px"
                  v-if="product.parentProduct"
                >
                  Parent: {{ product.parentProduct.name }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  style="font-size: 12px"
                  v-if="product.hsCode"
                >
                  HS Code: {{ product.hsCode.alternateCode }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon
                  color="success"
                  v-if="
                    company.organisationRelationshipProducts
                      .map((x) => x.productId)
                      .includes(product.id)
                  "
                  >check</v-icon
                >
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="filterProducts.length == 0 && !productLoading">
              <v-list-item-content class="text-center">
                <span style="font-size: 12px; color: grey"
                  >No products found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="shippingLineModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card :loading="loadingShippingLines">
        <v-toolbar flat>
          <v-toolbar-title> Manage Shipping Lines </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="shippingLineModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            dense
            clearable
            prepend-inner-icon="search"
            placeholder="Search"
            v-model="shippingLineSearch"
          ></v-text-field>
          <v-list
            dense
            style="max-height: 50vh; overflow-y: auto"
            :key="shippingLineRefresh"
          >
            <v-list-item
              v-for="shippingLine in filterShippingLines"
              :key="shippingLine.id"
              @click="setShippingLine(shippingLine)"
            >
              <v-list-item-action>
                <v-avatar :color="shippingLine.logo ? 'white' : 'secondary'">
                  <v-img
                    v-if="shippingLine.logo"
                    :src="shippingLine.logo"
                    contain
                  ></v-img>
                  <h2 v-else>
                    {{ shippingLine.name.charAt(0) }}
                  </h2>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ shippingLine.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="filterShippingLines.length == 0 && !loadingShippingLines"
            >
              <v-list-item-content>
                <span style="font-size: 12px; color: grey"
                  >No shipping lines found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="shippingLineItemModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title> Manage Shipping Line </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="secondary"
            @click="saveShippingLine"
            :loading="savingShippingLineItem"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn
            icon
            color="red"
            v-if="shippingLineItem.id"
            :loading="deletingShippingLine"
            @click="deleteShippingLine(shippingLineItem)"
            ><v-icon>delete</v-icon></v-btn
          >
          <v-btn text @click="shippingLineItemModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list>
            <v-list-item v-if="shippingLineItem.carrier">
              <v-list-item-action>
                <v-avatar
                  :color="shippingLineItem.carrier.logo ? 'white' : 'secondary'"
                >
                  <v-img
                    v-if="shippingLineItem.carrier.logo"
                    :src="shippingLineItem.carrier.logo"
                    contain
                  ></v-img>
                  <h2 v-else>
                    {{ shippingLineItem.carrier.name.charAt(0) }}
                  </h2>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ shippingLineItem.carrier.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-text-field
              label="Contract Number"
              outlined
              dense
              clearable
              v-model="shippingLineItem.contractNumber"
            ></v-text-field>
            <v-select
              label="Contract Owner"
              outlined
              dense
              hide-details
              :items="parties"
              item-text="name"
              item-value="id"
              v-model="shippingLineItem.contractOwnerId"
            ></v-select>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import AddressModal from "../SystemComponents/AddressModal.vue";



export default {
  props: ["company", "teams"],
  components: {
    AddressModal,
  },
  data: () => ({
    alias: null,
    aliasModal: false,
    accountManagerModal: false,
    addressItem: {},
    addressDialog: false,
    allTeams: [],
    clientStatus: null,
    clientStatusItems: ["GOOD STANDING", "INACTIVE", "ON HOLD", "COD"],
    countries: [],
    countryModal: false,
    countryRefresh: 4000,
    companyLoading: false,
    contactPersons: [],
    deletingShippingLine: false,
    editingAccountReference: false,
    fieldKey: 3000,
    headers: [
      {
        text: "Name",
        value: "user.firstname",
        align: "center",
      },
      {
        text: "Surname",
        value: "user.surname",
        align: "center",
      },
      {
        text: "Email",
        value: "user.emailAddress",
        align: "center",
      },
      {
        text: "Position",
        value: "position",
        align: "center",
      },
      {
        text: "Office",
        value: "organisationAddress.alias",
        align: "center",
      },
    ],
    loadingAddress: false,
    loadingCountries: false,
    loadingShippingLines: false,
    loadingUsers: false,
    operatingCountries: [],
    productKey: 5000,
    productModal: false,
    products: {
      total: 0,
      data: [],
    },
    productionAliases: [],
    productLoading: false,
    savingClientStatus: false,
    savingAccountReference: false,
    savingShippingLineItem: false,
    savingTeams: false,
    searchCountry: null,
    searchProduct: null,
    selectedTeams: [],
    shippingLineModal: false,
    shippingLines: [],
    shippingLineItem: {},
    shippingLineItemModal: false,
    shippingLineRefresh: 6000,
    shippingLineSearch: null,
    teamModal: false,
    users: [],
    userSearch: null,
  }),
  watch: {
    "company.relatedOrganisation.id": {
      handler: function () {
        this.getCompanyContacts();
        // this.getOperatingCountries()
      },
      immediate: true,
    },
  },
  computed: {
    filterCountries() {
      let result = this.countries;
      if (this.searchCountry) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchCountry.toLowerCase())
        );
      }
      return result;
    },
    filterProducts() {
      let result = this.products.data;
      if (this.searchProduct) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchProduct.toLowerCase())
        );
      }
      return result;
    },
    filterOrgUsers() {
      let result = this.users;
      if (this.userSearch) {
        result = result.filter(
          (x) =>
            x.user.firstname
              .toLowerCase()
              .includes(this.userSearch.toLowerCase()) ||
            x.user.surname.toLowerCase().includes(this.userSearch.toLowerCase())
        );
      }
      return result;
    },
    filterShippingLines() {
      let result = this.shippingLines;
      if (this.shippingLineSearch) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.shippingLineSearch.toLowerCase())
        );
      }
      return result;
    },
    parties() {
      return [this.company.relatedOrganisation, this.company.organisation];
    },
  },
  created() {
    this.getOrganisationUsers();
    this.getProductionAliases();
    this.getTeams();
    this.getCountries();
    this.getProducts();
    this.getShippingLines();
  },
  methods: {

    addAddress() {
      this.addressItem = {
        organisationId: this.company.relatedOrganisation.id,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        addressLine4: null,
        postalCode: null,
        country: this.company.relatedOrganisation.country,
        countryCode: this.company.relatedOrganisation.countryCode,
      };
      this.addressItem.new = true;
      this.addressDialog = true;
    },
    async addOperatingCountry(country) {
      this.loadingCountries = true;
      let find = this.company.organisationRelationshipCountries.find(
        (x) => x.countryRegionId == country.id
      );
      if (find) {
        let obj = {
          id: find.id,
          isActive: false,
          isDeleted: true,
        };
        await this.$API.updateOrganisationRelationshipCountry(obj);
        let index = this.company.organisationRelationshipCountries.findIndex(
          (x) => x.id == find.id
        );
        this.company.organisationRelationshipCountries.splice(index, 1);
        this.$message.success("Successfully removed country!");
      } else {
        let obj = {
          organisationRelationshipId: this.company.id,
          countryRegionId: country.id,
        };
        let result = await this.$API.addOrganisationRelationshipCountry(obj);
        obj = result;
        obj.countryRegion = country;
        this.company.organisationRelationshipCountries.push(obj);
        this.$message.success("Successfully added country!");
      }
      this.countryRefresh++;
      this.loadingCountries = false;
      // this.countryModal = false
    },

    async addTradedProduct(product) {
      this.productLoading = true;
      let find = this.company.organisationRelationshipProducts.find(
        (x) => x.productId == product.id
      );
      if (find) {
        let obj = {
          id: find.id,
          isActive: false,
          isDeleted: true,
        };
        await this.$API.updateOrganisationRelationshipProduct(obj);
        let index = this.company.organisationRelationshipProducts.findIndex(
          (x) => x.id == find.id
        );
        this.company.organisationRelationshipProducts.splice(index, 1);
        this.$message.success("Successfully removed product!");
      } else {
        let obj = {
          organisationRelationshipId: this.company.id,
          productId: product.id,
        };
        let result = await this.$API.addOrganisationRelationshipProduct(obj);
        obj = result;
        obj.product = product;
        this.company.organisationRelationshipProducts.push(obj);
        this.$message.success("Successfully added product!");
      }
      this.productKey++;
      this.productLoading = false;
      // this.countryModal = false
    },
    deleteAddress(item, index) {
      this.$confirm(
        "Are you sure you want to delete this address?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          if (item.id) {
            await this.$API.updateOrganisationAddress({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
          }
          this.company.relatedOrganisation.organisationAddresses.splice(
            index,
            1
          );
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {});
    },
    deleteShippingLine(item) {
      this.$confirm(
        "Are you sure you want to remove this shipping line?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          this.deletingShippingLine = true;
          if (item.id) {
            await this.$API.updateOrganisationRelationshipTransport({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
          }
          let index = this.company.organisationRelationshipTransports.findIndex(
            (x) => x.id == item.id
          );
          this.company.organisationRelationshipTransports.splice(index, 1);
          this.shippingLineRefresh++;
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
          this.deletingShippingLine = false;
          this.shippingLineItemModal = false;
          this.shippingLineItem = {};
        })
        .catch(() => {
          this.deletingShippingLine = false;
        });
    },
    editAccountManager() {
      this.accountManagerModal = true;
    },
    editAccountReference() {
      this.editingAccountReference = true;
      this.$nextTick(() => {
        this.$refs.accountReference.focus();
        // this.$refs['accountReference'].$refs.input.unfocus();
      });
    },
    editAddress(address, index) {
      this.addressItem = address;
      this.addressItem.index = index;
      this.addressItem.modalType = "Edit";
      this.addressDialog = true;
    },
    editShippingLine(item) {
      this.shippingLineItem = item;
      this.shippingLineItemModal = true;
    },
    editTeams() {
      this.selectedTeams = this.teams.map((team) => team.teamId);
      this.teamModal = true;
    },
    async getCountries() {
      this.loadingCountries = true;
      this.countries = await this.$API.searchCountriesBasic({});
      this.loadingCountries = false;
    },
    async getCompanyContacts() {
      try {
        this.contactPersons = await this.$API.getCompanyContacts(
          this.company.relatedOrganisation.id
        );
      } catch (error) {
        console.log(error);
      }
    },
    async getOrganisationUsers() {
      this.loadingUsers = true;
      this.users = await this.$API.getOrganisationUsers();
      this.loadingUsers = false;
    },
    async getProductionAliases() {
      this.productionAliases = await this.$API.getProductionAliases({
        producerId: this.company.relatedOrganisation.id,
      });
    },
    async getProducts() {
      this.productLoading = true;
      this.products = await this.$API.getProducts({
        params: {
          filter: {
            type: "Product",
          },
        },
      });
      this.productLoading = false;
    },
    async getShippingLines() {
      this.loadingShippingLines = true;
      this.shippingLines = await this.$API.getShippingLines();
      this.loadingShippingLines = false;
    },
    async getTeams() {
      this.allTeams = await this.$API.getTeams();
    },
    getTeamMemberCount(id) {
      let findTeam = this.allTeams.find((team) => team.id == id);
      return findTeam ? findTeam.members : 0;
    },
    saveAddress(address) {
      this.loadingAddress = true;
      if (
        this.company.relatedOrganisation &&
        this.company.relatedOrganisation.id
      ) {
        if (address.id) {
          let index =
            this.company.relatedOrganisation.organisationAddresses.findIndex(
              (x) => x.id == address.id
            );
          if (index > -1) {
            this.company.relatedOrganisation.organisationAddresses[index] =
              address;
          } else {
            this.company.relatedOrganisation.organisationAddresses.push(
              address
            );
          }
        } else {
          let index =
            this.company.relatedOrganisation.organisationAddresses.findIndex(
              (x) => x.index == address.index
            );
          if (index > -1) {
            this.company.relatedOrganisation.organisationAddresses[index] =
              address;
          } else {
            this.company.relatedOrganisation.organisationAddresses.push(
              address
            );
          }
        }
      }
      //   this.loadHQ();
      this.addressDialog = false;
      this.loadingAddress = false;
      this.addressItem = {};
    },
    async saveTeams() {
      this.savingTeams = true;
      let create = this.selectedTeams.filter(
        (x) => !this.teams.map((y) => y.teamId).includes(x)
      );
      let remove = this.teams.filter(
        (x) => !this.selectedTeams.includes(x.teamId)
      );

      if (create.length > 0) {
        await this.$API.createRelationshipTeam({
          batch: create.map((x) => ({
            teamId: x,
            organisationRelationshipId: this.company.id,
          })),
        });
      } else {
        for (let j = 0; j < remove.length; j++) {
          let relationshipteamId = this.teams.find(
            (x) => x.teamId == remove[j].teamId
          ).id;
          await this.$API.updateRelationshipTeam({
            id: relationshipteamId,
            isActive: false,
            isDeleted: true,
          });
        }
      }
      this.$emit("refreshTeams");
      this.savingTeams = false;
      this.teamModal = false;
    },
    async saveRelationship() {
      if (!this.company.isCustomer && !this.company.isSupplier) {
        this.company.isCustomer = true;
      }
      this.company.accountManagerId = this.company.accountManager
        ? this.company.accountManager.id
        : null;
      await this.$API.updateRelation(this.company);
      this.$message.success("Successfully Updated!");
      this.$emit("refresh", this.company);
    },
    async saveShippingLine() {
      this.savingShippingLineItem = true;
      if (this.shippingLineItem.id) {
        await this.$API.updateOrganisationRelationshipTransport(
          this.shippingLineItem
        );
        this.$message.success("Successfully updated!");
        let index = this.company.organisationRelationshipTransports.findIndex(
          (x) => x.id == this.shippingLineItem.id
        );
        if (this.shippingLineItem.contractOwnerId) {
          let owner = this.parties.find(
            (x) => x.id == this.shippingLineItem.contractOwnerId
          );
          this.shippingLineItem.contractOwner = owner;
        } else {
          this.shippingLineItem.contractOwner = null;
        }
        this.company.organisationRelationshipTransports[index] =
          this.shippingLineItem;
      } else {
        let result = await this.$API.addOrganisationRelationshipTransport(
          this.shippingLineItem
        );
        result.carrier = this.shippingLineItem.carrier;
        if (this.shippingLineItem.contractOwnerId) {
          let owner = this.parties.find(
            (x) => x.id == this.shippingLineItem.contractOwnerId
          );
          result.contractOwner = owner;
        }
        this.company.organisationRelationshipTransports.push(result);
        this.$message.success(
          `Successfully added ${this.shippingLineItem.carrier.name}!`
        );
      }
      this.shippingLineRefresh++;
      this.shippingLineItemModal = false;
      this.savingShippingLineItem = {};
      this.shippingLineItem = {};
      // this.countryModal = false
    },
    updateAccountManager(user) {
      this.company.accountManager = user.user;
      this.company.accountManagerId = user.userId;
      console.log(user);
      this.$emit("refresh", this.company);
      this.saveRelationship();
      this.accountManagerModal = false;
    },
    async updateClientStatus() {
      this.savingClientStatus = true;
      let obj = {
        id: this.$route.params.id,
        clientStatus: this.clientStatus,
      };
      await this.$API.updateCustomerStatus(obj);
      this.$message.success("Successfully Updated Status!");
      this.savingClientStatus = false;
    },
    async saveAccountReference() {
      this.savingAccountReference = true;
      await this.$API.updateRelation(this.company);
      this.$message.success("Successfully Updated reference!");
      this.editingAccountReference = false;
      this.savingAccountReference = false;
    },

    setShippingLine(shippingLine) {
      let rank = 1;
      if (this.company.organisationRelationshipTransports.length > 0) {
        rank =
          this.company.organisationRelationshipTransports[
            this.company.organisationRelationshipTransports.length - 1
          ].rank + 1;
      }
      this.shippingLineItem = {
        rank,
        carrierId: shippingLine.id,
        carrier: shippingLine,
        organisationRelationshipId: this.company.id,
        contractOwnerId: this.$store.state.currentOrg.id,
      };
      this.shippingLineItemModal = true;
      this.shippingLineModal = false;
    },
    async removeAlias(alias) {
      await this.$API.updateProductionAlias({
        id: alias.id,
        isActive: false,
        isDeleted: true,
      });
      let index = this.productionAliases.findIndex(
        (item) => item.id == alias.id
      );
      this.productionAliases.splice(index, 1);
      this.aliasKey++;
    },
    async saveInternalAlias() {
      let result = await this.$API.updateProductionAliasName(this.company.id, {
        alias: this.company.alias,
        producerId: this.company.relatedOrganisation.id,
      });
      this.$message.success("Successfully updated alias!");
    },
    async saveAlias() {
      if (this.alias) {
        let result = await this.$API.createProductionAlias({
          code: this.alias,
          producerId: this.company.relatedOrganisation.id,
        });
        this.alias = null;
        this.aliasModal = false;
        this.productionAliases.push(result);
      }
    },
    updateField(field) {
      if (!this.company[field]) {
        this.company[field] = true;
      } else {
        this.company[field] = false;
      }
      this.fieldKey++;
      this.saveRelationship();
    },
  },
};
</script>

<style scoped>
.infoCards {
  background:  var(--v-greyRaised-lighten1) !important;
  contain: content;
  border-radius: 10px;
}
.innerContent {
  max-height: calc(100vh - 36px - 30px) !important;
  height: 100%;
  width: 100%;
  contain: content;
  overflow-y: auto;
}
</style>